<template>
  <div
    class="flex space-x-3 px-6"
    :class="{
      'justify-center': onlyOneButton
    }"
  >
    <outline-button
      v-if="onCancel"
      :class="{ 'w-3/4': onlyOneButton }"
      data-cy="modal-button-cancel"
      @click="onCancel"
    >
      {{ $t("COMMON.CANCEL") }}
    </outline-button>

    <color-button
      v-if="onDelete"
      type="error"
      :class="{ 'w-3/4': onlyOneButton }"
      data-cy="modal-button-delete"
      @click="onDelete"
    >
      {{ $t("COMMON.DELETE") }}
    </color-button>
    <primary-button
      v-if="onSave || saveIsSubmit"
      :type="saveIsSubmit ? 'submit' : 'button'"
      :disabled="disabled"
      :class="{ 'w-3/4': onlyOneButton }"
      data-cy="modal-button-save"
      @click="saveIsSubmit ? undefined : onSave?.()"
    >
      {{ saveButtonText || $t("COMMON.SAVE") }}
    </primary-button>
  </div>
</template>

<script lang="ts">
interface Props {
  onCancel?: () => void;
  onDelete?: () => void;
  onSave?: () => void;
  saveIsSubmit?: boolean;
  disabled?: boolean;
  saveButtonText?: string;
}
</script>

<script setup lang="ts">
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import ColorButton from "@/components/ui/buttons/ColorButton.vue";
import { computed } from "vue";

const props = withDefaults(defineProps<Props>(), {
  saveIsSubmit: false,
  disabled: false
});

const onlyOneButton = computed(
  () =>
    [props.onCancel, props.onDelete, props.onSave].filter(Boolean).length === 1
);
</script>
